import { Routes } from '@angular/router';
import { environment } from '../environments/environment';

export const routes: Routes = [
        { path: '', redirectTo: '', pathMatch: 'full' ,data: { title: environment.tabTitle.Home },},
    
        { path: '', data: { title: 'Home' },loadChildren: () => import('./free-login-module/home-page/home-page.module').then((m) => m.HomePageModule) },
         { path: 'about', data: { title: 'About' },loadChildren: () => import('./free-login-module/about/about.module').then((m) => m.AboutModule) },
         { path: 'products', data: { title: 'Prodacts' },loadChildren: () => import('./free-login-module/products/products.module').then((m) => m.ProductsModule) },
         { path: 'contact', data: { title: 'contact' },loadChildren: () => import('./free-login-module/contact/contact.module').then((m) => m.ContactModule) },


        { path: 'not-found',data: { title: environment.tabTitle.Home }, loadChildren: () => import('./shared/component/not-found/not-found.module').then((m) => m.NotFoundModule) },
    ];

