
import { Component, OnInit,OnDestroy,ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router, RouterModule, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { I18Service } from '../../../services/i18Service';
import { BaseService, LayoutService } from '../../../../shared';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { CommonModule } from '@angular/common';




@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone:true,
  imports:[CommonModule,RouterModule]

})
export class FooterComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  lang = this.baseService.currentLang || localStorage.getItem('lang') || 'ar';
  environment = environment;
  socialList = [];
  constructor(public router: Router,
    private translate: TranslateService, private baseService: BaseService,

  ) {     this.translate.use(this.lang);
  }

  ngOnInit(): void {
    // this.GetMenuList();
    this.baseService.getLangData().subscribe(data => {
      this.changeLagView(data.lang);

     });

  }
  changeLagView(lang){
   this.lang = lang;
    this.translate.use(lang);  }

  menuList = [];
  // GetMenuList() {
  //   let subscription = this.baseService.post("menus/GetMenus", {ReferenceId: environment.ReferenceId, Code: environment.EntitesCode.Home.code_MenuFooter })
  //     .subscribe({
  //       next: (res: any) => {
  //         this.menuList = res.output.MenusEntity.subMenus;
  //       }
  //       , error: (error: string) => {
  //          ;
  //       }
  //     });
  //   this.subscriptions.push(subscription);
  // }
  ngOnDestroy(): void {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }


  

  viewContent(item){
    if(item.url && item.url.length > 0)
   { this.router.navigate([item.url]);}    }



   scrollToTop(){
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
});
   }


}







