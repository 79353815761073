<div class="site-footer__top">
    <div class="container">
        <div class="site-footer__top-inner">
            <div class="site-footer-map" style="background-image: url(assets/images/shapes/site-footer-mape.png)"></div>
            <div class="row">
                <div class="col-xl-8 col-lg-8 col-md-8 wow fadeInUp animated" data-wow-delay="100ms" style="visibility: visible; animation-delay: 100ms; animation-name: fadeInUp;">
                    <div class="footer-widget__column footer-widget__about">
                        <div class="footer-widget__about-logo">
                            <a [routerLink]="['/']"><img src="assets/images/brand/logo2.png" width="280px" height="160px" alt=""></a>
                        </div>
                        <p class="footer-widget__about-text">Tafra Company  was established in 2016 <br>
                            Within 8 years Tafra is serving the Egyptian market with high-quality medical equipment <br> in partnership with the global healthcare elite suppliers with our specialized sales & marketing teams <br> in addition to our dedicated after-sales service support.
                             </p>
                        <!-- <div class="footer-widget__about-social">
                            <a href="#"><i class="fab fa-twitter"></i></a>
                            <a href="#"><i class="fab fa-facebook"></i></a>
                            <a href="#"><i class="fab fa-pinterest-p"></i></a>
                            <a href="#"><i class="fab fa-instagram"></i></a>
                        </div> -->
                    </div>
                </div>
             
                <div class="col-xl-4 col-lg-4 col-md-4 wow fadeInUp animated marginTop" data-wow-delay="300ms" style="visibility: visible; animation-delay: 300ms; animation-name: fadeInUp;">
                    <div class="footer-widget__column footer-widget__contact">
                        <h3 class="footer-widget__title">Contact</h3>
                        <p class="footer-widget__contact-text">Main Office : Building No. 1 , 12 St  (El-Dawaa St) <br> Sefarat Stars - Nasr city / Cairo

                        </p>
                        <ul class="list-unstyled footer-widget__contact-list">
                            <!-- <li>
                                <div class="icon">
                                    <span class="icon-email"></span>
                                </div>
                                <div class="text">
                                    <p><a href="mailto:Contact@3s-sa.com">Contact&commat;3s-sa.com</a></p>
                                </div>
                            </li> -->
                            <li>
                                <div class="icon">
                                    <span class="icon-telephone"></span>
                                </div>
                                <div class="text">
                                    <p>
                                        <a href="tel:">01287593074</a>
                                        /
                                        <a href="tel:">0223522529</a>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
           
            </div>
        </div>
    </div>
</div>

