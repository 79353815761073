/* src/app/app.component.scss */
.trialVersion {
  position: fixed;
  z-index: 14;
  background: #c98d3d;
  color: #ffffff;
  top: 50%;
  transform: rotate(-90deg);
  padding: 5px 17px 5px 18px;
  margin: 0px;
  left: -42px !important;
  border-radius: 0px 0px 10px 10px;
  -moz-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  -webkit-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}
.trialVersion:hover {
  background: #fbae1f;
  color: #ffffff;
}
.loading {
  font-size: 20px;
  font-weight: 600;
  color: white;
}
/*# sourceMappingURL=app.component-KP5I3UQZ.css.map */
