import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { routes } from './app.routes';
import { JwtTokenInterceptorService } from './shared/guard/jwt.interceptor';
import { AuthGuard, BaseService, LayoutService } from './shared';
import { NgCircleProgressModule } from 'ng-circle-progress';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export const provideTranslation = () => ({
  defaultLanguage: 'en',
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient],
  },
});
export function initializeApp(BaseService: BaseService) {
  return (): Promise<any> => BaseService.generateToken();
}
export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes),
    {
        provide:HTTP_INTERCEPTORS,
        useClass:JwtTokenInterceptorService,
        multi:true
    }
,
{
  provide: APP_INITIALIZER, 
  useFactory: initializeApp,
  deps: [BaseService],
 

  multi: true
},

    importProvidersFrom(
      HttpClientModule,
      BrowserModule,
      BrowserAnimationsModule,
      NgCircleProgressModule.forRoot({
        // set defaults here
        radius: 100,
        outerStrokeWidth: 16,
        innerStrokeWidth: 8,
        outerStrokeColor: "#78C000",
        innerStrokeColor: "#C7E596",
        animationDuration: 300,
      
      }),
      TranslateModule.forRoot(provideTranslation())
  
    )
  ]
   
};


