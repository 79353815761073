import { AfterViewInit, Component, OnInit,Renderer2,ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { I18Service } from './shared/services/i18Service';
import { PrimeNGConfig } from 'primeng/api';
import { LayoutService,BaseService } from './shared';
declare var $:any;
import { ActivatedRoute, NavigationStart, Router,NavigationEnd, RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
declare const gtag: Function;
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonModule } from '@angular/common';
import { SharedModule } from "./shared/shared.module";
import { FooterComponent } from "./shared/component/layoutComponent/footer/footer.component";
import { HeaderComponent } from './shared/component/layoutComponent/header/header.component';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [HeaderComponent, RouterModule, CommonModule,ReactiveFormsModule, FooterComponent,DialogModule, ButtonModule, InputTextModule]
})

export class AppComponent implements OnInit  {
    private subscriptions: Subscription[] = [];
    lang = this.baseService.currentLang || localStorage.getItem('lang') || 'ar';
environment=environment;
Form:FormGroup

    constructor(private translate: TranslateService,private spinner: NgxSpinnerService,private renderer: Renderer2,
        private i18Service: I18Service,private titleService: Title,
        private router: Router, private baseService: BaseService,private activatedRoute: ActivatedRoute,
        private primengConfig: PrimeNGConfig, private layoutService: LayoutService,    private fb: FormBuilder,
    ) {
        // this.translate.setDefaultLang(localStorage.getItem('lang') || 'ar');
        // localStorage.setItem('lang', 'ar')
        // this.spinner.show()
        this.Form = this.fb.group({
            name: ['', [Validators.required]],
            mobileNo: ['', [Validators.required, Validators.pattern('^(01)([0-9]{9})$')]],
            catagory:["", [Validators.required]]
         
      
          })
        this.router.events.subscribe((event) => {
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                  const pageTitle = this.getPageTitle(this.router.routerState, this.router.routerState.root).join(' | ');
                  this.titleService.setTitle(pageTitle);
                }
              });
          });

        // this.router.events.subscribe((event) => {
            
        //     if (event instanceof NavigationEnd) {
        //       gtag('config', environment.google_trackingId, { 'page_path': event.urlAfterRedirects });
        //     }      
        //   })
          
        this.translate.use(this.lang);
        activatedRoute.params.subscribe(val => {
            
            this.onActivate();
          });
        
         //this.baseService.checkedUserLoggedByAD();
        //router.events.subscribe((val) => this.checkRote())
    }
    get mobileNo() {
        return this.Form.get('mobileNo');
      }
      get name() {
        return this.Form.get('name');
      }
      get catagory() {
        return this.Form.get('catagory');
      }
      submit()
{
    debugger
  const controls = this.Form.controls;
  Object.keys(controls).forEach(key => {
    controls[key].markAsTouched();
  });
  
  

  if (!this.Form.valid) {
    return;
  }
  let val: any = [];
  
  val = JSON.parse(localStorage.getItem('data'));
  if(val==null){
    val=[]
  }
  val.push(this.Form.value);
  localStorage.setItem('data', JSON.stringify(val));
  this.visible=false
}
  
    get containerClass() {
        return {
            'layout-theme-light': this.layoutService.config.colorScheme === 'light',
            'layout-theme-dark': this.layoutService.config.colorScheme === 'dark',
            'layout-overlay': this.layoutService.config.menuMode === 'overlay',
            'layout-static': this.layoutService.config.menuMode === 'static',
            'layout-static-inactive': this.layoutService.state.staticMenuDesktopInactive && this.layoutService.config.menuMode === 'static',
            'layout-overlay-active': this.layoutService.state.overlayMenuActive,
            'layout-mobile-active': this.layoutService.state.staticMenuMobileActive,
            'p-input-filled': this.layoutService.config.inputStyle === 'filled',
            'p-ripple-disabled': !this.layoutService.config.ripple
        }

    }
    showHeader = true;
    checkRote(){
        if(this.router.url == '/'){
            this.showHeader =false;
        }else{
            this.showHeader =true;

        }
    }
 
    ngOnInit() {
 
    
        this.activatedRoute.params.subscribe((params) => {
            this.onActivate();

          });



        // this.primengConfig.ripple = true;
        // const dom: any = document.querySelector('body');
        // const language = localStorage.getItem('lang') || 'ar';
        // if (language === 'ar') {
        //     dom.classList.add('rtl');
        //     localStorage.setItem('lang', language);

        // } else {
        //     dom.classList.remove('rtl');

        // }

        const navbarMenu = document.getElementById("navbar");
        const overlayMenu = document.querySelector(".overlay");
    
        // Show and Hide Navbar Function
        const toggleMenu = () => {
            navbarMenu.classList.toggle("active");
            overlayMenu.classList.toggle("active");
        };
    
        // Collapsible Mobile Submenu Function
        const collapseSubMenu = () => {
            navbarMenu
                .querySelector(".menu-dropdown.active .submenu")
                .removeAttribute("style");
            navbarMenu.querySelector(".menu-dropdown.active").classList.remove("active");
        };
    
        // Toggle Mobile Submenu Function
        const toggleSubMenu = (e) => {
            if (e.target.hasAttribute("data-toggle") && window.innerWidth <= 991) {
                e.preventDefault();
                const menuDropdown = e.target.parentElement;
    
                // If Dropdown is Expanded, then Collapse It
                if (menuDropdown.classList.contains("active")) {
                    collapseSubMenu();
                } else {
                    // Collapse Existing Expanded Dropdown
                    if (navbarMenu.querySelector(".menu-dropdown.active")) {
                        collapseSubMenu();
                    }
    
                    // Expanded the New Dropdown
                    menuDropdown.classList.add("active");
                    const subMenu = menuDropdown.querySelector(".submenu");
                    subMenu.style.maxHeight = subMenu.scrollHeight + "px";
                }
            }
        };
    
        // Fixed Resize Window Function
        const resizeWindow = () => {
            if (window.innerWidth > 991) {
                if (navbarMenu.classList.contains("active")) {
                    toggleMenu();
                }
                if (navbarMenu.querySelector(".menu-dropdown.active")) {
                    collapseSubMenu();
                }
            }
        };
    
        // Initialize Event Listeners
        // overlayMenu.addEventListener("click", toggleMenu);
        // navbarMenu.addEventListener("click", toggleSubMenu);
        // window.addEventListener("resize", resizeWindow);
    
    
    
        $('.cancel').click(function() {
            $('.navbar').removeClass("active");
            $('.overlay').removeClass("active");
        });
    
        $('.menu-item').click(function() {
            $('.menu-item ').removeClass("activelink");
            $(this).addClass("activelink");
        });
    
    
        $('.icon .bottom').click(function() {
            $('body').addClass("active");
            $(".searchpop").fadeIn("");
        });
        $('.exit').click(function() {
            $('body').removeClass("active");
            $(".searchpop").fadeOut("");
        });
    
        // End Menu
    
        $(window).scroll(function() {
            if ($(this).scrollTop() > 1) {
                $('.header').addClass("sticky");
            } else {
                if ($(this).scrollTop() < 1) {
                    $('.header').removeClass("sticky");
                }
            }
        });
    
        // End Scroll Header
        $(window).on("scroll", function() {
            if ($(".stricked-menu").length) {
                var headerScrollPos = 130;
                var stricky = $(".stricked-menu");
                if ($(window).scrollTop() > headerScrollPos) {
                    stricky.addClass("stricky-fixed");
                } else if ($(this).scrollTop() <= headerScrollPos) {
                    stricky.removeClass("stricky-fixed");
                }
            }
           
        });
        $(window).scroll(function() {
         
        });
        //Click event to scroll to top
     
    
        // End Scroll Top
    
    

    

        // End clients
    
        // $('.services-slider').slick({
        //     dots: true,
        //     infinite: true,
        //     slidesToShow: 4,
        //     slidesToScroll: 1,
        //     autoplay: true,
        //     autoplaySpeed: 3000,
        //     responsive: [{
        //             breakpoint: 991,
        //             settings: {
        //                 slidesToShow: 2,
        //                 slidesToScroll: 1
        //             }
        //         },
        //         {
        //             breakpoint: 550,
        //             settings: {
        //                 slidesToShow: 1,
        //                 slidesToScroll: 1
        //             }
        //         }
        //     ]
        // });

        this.router.events.subscribe(event => {
            if(event instanceof NavigationStart){
              let routing= event.url;
              
            //   this.checkToken(routing);
            //   this.checkRoute(routing)
   
             }
           });


           $.fn.countTo = function(options) {
            options = options || {};
    
                return $(this).each(function() {
                    // set options for current element
                    var settings = $.extend({}, $.fn.countTo.defaults, {
                        from: $(this).data('from'),
                        to: $(this).data('to'),
                        speed: $(this).data('speed'),
                        refreshInterval: $(this).data('refresh-interval'),
                        decimals: $(this).data('decimals')
                    }, options);
        
                    // how many times to update the value, and how much to increment the value on each update
                    var loops = Math.ceil(settings.speed / settings.refreshInterval),
                        increment = (settings.to - settings.from) / loops;
        
                    // references & variables that will change with each update
                    var self = this,
                        $self = $(this),
                        loopCount = 0,
                        value = settings.from,
                        data = $self.data('countTo') || {};
        
                    $self.data('countTo', data);
        
                    // if an existing interval can be found, clear it first
                    if (data.interval) {
                        clearInterval(data.interval);
                    }
                    data.interval = setInterval(updateTimer, settings.refreshInterval);
        
                    // initialize the element with the starting value
                    render(value);
        
                    function updateTimer() {
                        value += increment;
                        loopCount++;
        
                        render(value);
        
                        if (typeof(settings.onUpdate) == 'function') {
                            settings.onUpdate.call(self, value);
                        }
        
                        if (loopCount >= loops) {
                            // remove the interval
                            $self.removeData('countTo');
                            clearInterval(data.interval);
                            value = settings.to;
        
                            if (typeof(settings.onComplete) == 'function') {
                                settings.onComplete.call(self, value);
                            }
                        }
                    }
        
                    function render(value) {
                        var formattedValue = settings.formatter.call(self, value, settings);
                        $self.html(formattedValue);
                    }
                });
            };
        
            $.fn.countTo.defaults = {
                from: 0, // the number the element should start at
                to: 0, // the number the element should end at
                speed: 1000, // how long it should take to count between the target numbers
                refreshInterval: 100, // how often the element should be updated
                decimals: 0, // the number of decimal places to show
                formatter: formatter, // handler for formatting the value before rendering
                onUpdate: null, // callback method for every time the element is updated
                onComplete: null // callback method for when the element finishes updating
            };
        
            function formatter(value, settings) {
                return value.toFixed(settings.decimals);
            }

        
            // this.baseService.getLangData().subscribe(data => {
            //     this.changeLagView(data.lang);
          
            //    });
             
          
            }
            // ngAfterViewInit() {
            //     let loader = this.renderer.selectRootElement('#loader');
            //     this.renderer.setStyle(loader, 'display', 'none');
            //     // this.spinner.hide()
            //   }
            changeLagView(lang){
             this.lang = lang;
              this.translate.use(lang);  }
          
    GetToken() {
        // let subscription = this.baseService.post('users/GenerateToken', {})
        // .subscribe({
        //     next: (res: any) => {
        //         localStorage.setItem('token', res.output.UserJWT)
        //         let _url = localStorage.getItem('router')
        //         if(_url && _url !='/')
        //             this.router.navigateByUrl(_url)
        //         else 
        //         this.router.navigateByUrl('/home')
                
        //         this.showHeader =true;


        //     }
        //     , error: (error: string) => {
        //        ;
        //     }
        //   });
        // this.subscriptions.push(subscription);
      }

      checkToken(router){
        let token =localStorage.getItem('token')
            if(token==null){     
            localStorage.setItem('router',router)
            this.showHeader =false;
            this.GetToken();
        }else{
            if(router == '/') {
                this.showHeader =true;
                this.router.navigateByUrl('/home')
            } 
        }
      }

      checkRoute(router){
        if(router == 'loading') {
            this.showHeader =false;
        } else{
            this.showHeader =true;

        }
      }
      onActivate() {
        // window.scroll(0,0);
     
        window.scroll({ 
                top: 0, 
                left: 0, 
                behavior: 'smooth' 
         });
        }


        private getPageTitle(state: any, parent: any): string[] {
            const data: string[] = [];
            if (parent && parent.snapshot.data && parent.snapshot.data.title) {
              const titleData = parent.snapshot.data.title;
              if (typeof titleData === 'string') {
                data.push(titleData);
              } else if (typeof titleData === 'object') {
                data.push(titleData[this.lang]);
              }
            }
          
            if (state && parent) {
              data.push(...this.getPageTitle(state, state.firstChild(parent)));
            }
          
            return Array.from(new Set(data));
          }
          visible: boolean = false;

          showDialog() {
              this.visible = true;
              this.Form.reset()
          }
      
} 