import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { BaseService, LayoutService } from '../services';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';

import { tap, finalize, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class JwtTokenInterceptorService implements HttpInterceptor {
  count = 0;
  private _BaseService:BaseService;

  constructor(private spinner: NgxSpinnerService,private router:
    Router,private injector: Injector,private layoutService: LayoutService , ) { 
      setTimeout(() => {
        this._BaseService = this.injector.get(BaseService)
     })
    }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> | any {

    this.spinner.show('3sCMS');

     
    this.count++;
    const token  = localStorage.getItem('token')?.split('"').join('');

    if (token) {
      if(!request.url.includes('openweathermap')){
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
            isPortal:'true'
          },
        });
      }



      

    }
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          this.spinner.hide('3sCMS');


        }

        return next.handle(request).pipe(
          catchError((err) => {
           
            if (err.status === 401) {
              this.spinner.hide('3sCMS');

            }
            const error = err.error.message || err.statusText;
            return throwError(() => new Error(error.message))
          })
        );
      })).pipe(finalize(() => {

        if (this.count > 0) {
          this.count--;

        }
        if (this.count == 0) {
          this.spinner.hide('3sCMS');

        }
      })
      )
      ;
  }
}
