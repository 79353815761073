<header class="main-header clearfix">
   
    <nav class="main-menu clearfix">
        <div class="container">
            <div class="main-menu-wrapper__left clearfix">
                <div class="main-menu-wrapper__logo">
                    <a [routerLink]="['/']"><img src="assets/images/brand/logo.jpg" alt=""></a>
                </div> 
                <div class="main-menu-wrapper__main-menu">
                    <a class="mobile-nav__toggler" (click)="opennav()"><i class="fa fa-bars"></i></a>
                    <ul class="main-menu__list">
                        <li [ngClass]="{'current': currentHome == true}" (click)="setcurrentHome()">
                            <a [routerLink]="['/']">Home </a>
                        </li>
                        <li [ngClass]="{'current': currentAbout == true}" (click)="setcurrentAbout()"><a [routerLink]="['/about']">About</a></li>
                            <li [ngClass]="{'current': currentProduct == true}" (click)="setcurrentproduct()" class="dropdown">
                        
                                <a >Products </a>
                                <ul>
                                    <li (click)="setcurrentproduct()" > <a [routerLink]="['/products/Dental']">Dental</a></li>
                                    <li (click)="setcurrentproduct()" > <a [routerLink]="['/products/Digital']">Digital Radiography System </a></li>
                                    <li (click)="setcurrentproduct()"> <a [routerLink]="['/products/Scopes']">Endoscope Products </a> </li>
                                    <li (click)="setcurrentproduct()"> <a [routerLink]="['/products/Monitor']">Monitor</a> </li>
                                    <li (click)="setcurrentproduct()"> <a [routerLink]="['/products/Precise']">Precise Anesthesia </a> </li>
                                    <li (click)="setcurrentproduct()"> <a [routerLink]="['/products/UltraSound']">Ultrasound </a> </li>
                                    <li (click)="setcurrentproduct()"> <a [routerLink]="['/products/Support']">Ventilation Support </a> </li>
        
                                </ul>
                            
                        </li>
                        <li [ngClass]="{'current': currentContact == true}" (click)="setcurrentcontact()"><a [routerLink]="['/contact']">Contact</a></li>
                    </ul>
                </div>
            </div>
          
        </div>
    </nav>
</header>

<div class="stricky-header stricked-menu main-menu">

    <div class="container">
        <div class="main-menu-wrapper__left clearfix">
            <div class="main-menu-wrapper__logo">
                <a [routerLink]="['/']"><img src="assets/images/brand/logo.jpg" alt=""></a>
            </div>
            <div class="main-menu-wrapper__main-menu">
                <a href="#" class="mobile-nav__toggler"><i class="fa fa-bars"></i></a>
                <ul class="main-menu__list">
                    <li [ngClass]="{'current': currentHome == true}" (click)="setcurrentHome()">
                        <a [routerLink]="['/']">Home </a>
                    </li>
                    <li [ngClass]="{'current': currentAbout == true}" (click)="setcurrentAbout()"><a [routerLink]="['/about']">About</a></li>
                     <li [ngClass]="{'current': currentProduct == true}" (click)="setcurrentproduct()" class="dropdown">
                        
                        <a >Products </a>
                        <ul>
                            <li (click)="setcurrentproduct()" > <a [routerLink]="['/products/Dental']">Dental</a></li>
                            <li (click)="setcurrentproduct()" > <a [routerLink]="['/products/Digital']">Digital Radiography System </a></li>
                            <li (click)="setcurrentproduct()"> <a [routerLink]="['/products/Scopes']">Endoscope Products </a> </li>
                            <li (click)="setcurrentproduct()"> <a [routerLink]="['/products/Monitor']">Monitor</a> </li>
                            <li (click)="setcurrentproduct()"> <a [routerLink]="['/products/Precise']">Precise Anesthesia </a> </li>
                            <li (click)="setcurrentproduct()"> <a [routerLink]="['/products/UltraSound']">Ultrasound </a> </li>
                            <li (click)="setcurrentproduct()"> <a [routerLink]="['/products/Support']">Ventilation Support </a> </li>

                        </ul>
                    </li>
               
                    <li [ngClass]="{'current': currentContact == true}" (click)="setcurrentcontact()"><a [routerLink]="['/contact']">Contact</a></li>
                </ul>
            </div>
        </div>
     
    </div>
    <!-- End container -->


</div>
<div class="mobile-nav__wrapper">
    <div class="mobile-nav__overlay mobile-nav__toggler" (click)="closenav()"></div>
    <!-- /.mobile-nav__overlay -->
    <div class="mobile-nav__content">
        <span class="mobile-nav__close mobile-nav__toggler" (click)="closenav()"><i class="fa fa-times"></i></span>

        <div class="logo-box">
            <a [routerLink]="['/']" aria-label="logo image"><img src="assets/images/brand/logo2.png"
                    width="155" alt="" /></a>
        </div>
        <!-- /.logo-box -->
        <div class="mobile-nav__container">
            <ul class="main-menu__list">
                <li [ngClass]="{'current': currentHome == true}" (click)="setcurrentHome()">
                    <a [routerLink]="['/']">Home </a>
                </li>
                <li [ngClass]="{'current': currentAbout == true}" (click)="setcurrentAbout()" >
                    <a [routerLink]="['/about']">About</a>
                </li>
                 
                    <li class="dropdown" [ngClass]="{'current': currentService == true}" >
                        <a class="">Products
                        <button aria-label="dropdown toggler"     [ngClass]="fristlevel ? ' expanded' : ''"
                        (click)="toggeledropdown()">
                        <svg
                                class="svg-inline--fa fa-angle-down" aria-hidden="true" focusable="false"
                                data-prefix="fas" data-icon="angle-down" role="img" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512" data-fa-i2svg="">
                                <path fill="currentColor"
                                    d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z">
                                </path>
                            </svg><!-- <i class="fa fa-angle-down"></i> Font Awesome fontawesome.com --></button></a>
                    <ul [ngStyle]="fristlevel?{'display': 'block'} : {'display': 'none'}">
                        <li (click)="setcurrentproduct()" > <a [routerLink]="['/products/Dental']">Dental</a></li>
                        <li (click)="setcurrentproduct()" > <a [routerLink]="['/products/Digital']">Digital Radiography System </a></li>
                        <li (click)="setcurrentproduct()"> <a [routerLink]="['/products/Scopes']">Endoscope Products </a> </li>
                        <li (click)="setcurrentproduct()"> <a [routerLink]="['/products/Monitor']">Monitor</a> </li>
                        <li (click)="setcurrentproduct()"> <a [routerLink]="['/products/Precise']">Precise Anesthesia </a> </li>
                        <li (click)="setcurrentproduct()"> <a [routerLink]="['/products/UltraSound']">Ultrasound </a> </li>
                        <li (click)="setcurrentproduct()"> <a [routerLink]="['/products/Support']">Ventilation Support </a> </li>                 </ul>
                </li>
                    <li [ngClass]="{'current': currentContact == true}" (click)="setcurrentcontact()"><a [routerLink]="['/contact']">Contact</a></li>
            </ul>
        </div>
        <!-- /.mobile-nav__container -->

        <!-- /.mobile-nav__contact --/>
       
        <!-- /.mobile-nav__top -->



    </div>
    <!-- /.mobile-nav__content -->
</div>
<!-- /.mobile-nav__wrapper -->

<div class="search-popup">
    <div class="search-popup__overlay search-toggler" (click)="closesearch()"></div>
    <!-- /.search-popup__overlay -->
    <div class="search-popup__content">
        <form action="#">
            <label for="search" class="sr-only">search here</label>
            <!-- /.sr-only -->
            <input type="text" id="search" placeholder="Search Here..." />
            <button type="submit" aria-label="search submit" class="thm-btn">
                <i class="icon-magnifying-glass"></i>
            </button>
        </form>
    </div>
    <!-- /.search-popup__content -->
</div>
<script>
</script>

<!-- /.stricky-header -->