<app-header *ngIf="showHeader"></app-header>
<!-- <div class="trialVersion">
    {{'trialVersion'|translate}}
</div> -->

<!-- <ngx-spinner  name="3sCMS" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff"
[fullScreen]="true"><p class="loading"> </ngx-spinner> -->


<router-outlet (activate)="onActivate()"></router-outlet>
<a class=" scroll-to-top" style="display: inline;" (click)="showDialog()">
    <span style="position: absolute; top: 50%; bottom: 50%; transform: translate(-9px, -17px);"> <i
            class="fa-solid fa-phone"></i></span>
</a>
<app-footer *ngIf="showHeader" class="site-footer"></app-footer>

<!-- <app-footer *ngIf="showHeader"></app-footer> -->
<!-- <ngx-spinner bdColor="rgba(7,1,1,0.95)" size="small" type="pacman">
    <p style="color: white">Loading  </p>
</ngx-spinner> -->
<p-dialog [modal]="true" [(visible)]="visible"             header="Contact Us" 
>
     
    <div  class="contact-page__right">
        <form [formGroup]="Form">
            <div  class="row">
                <div  class="col-xl-6">
                    <div  class="comment-form__input-box">
                        <input class="form-control" type="text" placeholder="Full Name" name="name" formControlName="name" [class.is-invalid]=" name?.touched && name?.invalid" ></div>
                </div>
                <div  class="col-xl-6">
                    <div  class="comment-form__input-box"><input 
                            type="text" class="form-control" placeholder="Number" name="Number" formControlName="mobileNo" [class.is-invalid]=" mobileNo?.touched && mobileNo?.invalid" ></div>
                </div>
                <div  class="col-xl-6 mb-2">
                    <div  class="comment-form__input-box">
                        <select id="country" class="form-control" formControlName="catagory"  >
                            <option  [ngValue]="undefined" disabled selected>Choose Catagory</option>
                            <option  value="Cardiologist" >Cardiologist</option>
                            <option  value="Dentist" >Dentist</option>
                            <option  value="Dermatologist" >Dermatologist</option>
                            <option  value="Endocrinologist" >Endocrinologist</option>
                            <option  value="Gastroenterologist" >Gastroenterologist</option>
                            <option  value="Gynecologist" >Gynecologist</option>
                            <option  value="Internists" >Internists</option>
                            <option  value="Neonatologist" >Neonatologist</option>
                            <option  value="Orthodontist" >Orthodontist</option>
                            <option  value="Pediatrician" >Pediatrician</option>
                            <option  value="Plastic Surgeon" >Plastic Surgeon</option>
                            <option  value="Urologist" >Choose Catagory</option>


                           </select> 
                    </div>
                </div>
            </div>
            <div  class="row">
                <div  class="col-xl-12">
                <p *ngIf="this.Form.invalid" style="color: red;"> *You Must fill All Recored Correctaly </p>
                    <button (click)="submit()" class="thm-btn comment-form__btn"> Send Now</button>
                </div>
            </div>
        </form>
    </div>
 
</p-dialog>